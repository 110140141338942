<template>
  <div class="about" style="height: 100vh;display: flex;justify-content:center;align-items: center">
    <el-card style="width: 350px;padding: 30px ">
      <h3 style="text-align: center;color: #787878;margin: 30px 0">用户登录</h3>
      <el-form  ref="form" :model="form" :rules="rules" label-position="top"
                label-width="80px">
        <el-form-item label="账号" prop="name">
          <el-input v-model="form.name" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码" show-password />
        </el-form-item>
        <el-form-item label="">
          <div style="text-align: center">
            <el-button :loading="loading" style="width: 100%;margin-top:30px;" size="medium" type="primary" @click="tabsLogin()" >登录</el-button>
          </div>
          </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
// @ is an alias to /src
import {getMenu, updateBook} from "@/api/common"
export default {
  name: 'Home',
  data(){
    return {
      loading: false,
      form: {
        name: 'admin',
        password: ',./,./',
      },
      sebarList: [],
      rules: {
        name: [
          {
            required: true,
            message: '请填写用户名',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请填写密码',
            trigger: 'blur',
          },
        ]
      },
    }
  },
  created() {
    this.keyupSubmit()
  },
  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.tabsLogin()
        }
      }
    },
    tabsLogin() {
      this.loading=true
      let that=this
      updateBook(this.form).then((res) => {
        console.log(res)
        if(res.code==200){
          localStorage.setItem('Token',res.data.access_token)
          localStorage.setItem('user',JSON.stringify(res.data.user))
          getMenu().then((res)=>{
            localStorage.setItem('menu',JSON.stringify(res.data.menu))
            localStorage.setItem('api',JSON.stringify(res.data.api))
          }).catch((err)=>{

          })
          this.$message.success(res.msg)
          setTimeout(function () {
            that.loading=false
            that.$router.push('/home/about')
          },1000)
        }
        else if(res.code==0) {
          this.$message.success(res.msg)
          this.loading=false
        }
      });
    },
  }
}
</script>